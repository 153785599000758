import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Avatar,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  Input,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Map from '../components/map/map';
import { supabase } from '../supabase/supabase.config';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function CreateRestaurant() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [open, setOpen] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('sample message');
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');

  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');

  const [markerPosition, setMarkerPosition] = useState({
    lat: 6.927079,
    lng: 79.861244,
  });

  const navigate = useNavigate();

  // Function to handle image selection
  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const fetch = async () => {
    const user = JSON.parse(localStorage.getItem('User'));
    try {
      const { data, error } = await supabase
        .from('RestaurantAdmin')
        .select(
          `
      *,
      restID(*)
      `
        )
        .eq('userID', user.id)
        .single()
        .limit(1);

      if (error) {
        console.error('Error fetching data:', error);
        sessionStorage.removeItem('Admin');
      } else {
        console.log(data);
        sessionStorage.setItem('Admin', JSON.stringify(data));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  function validateSriLankanPhoneNumber(phoneNumber) {
    // Define a regular expression for Sri Lankan phone numbers
    const regex =
      /^(?:\+94|0)(?:11|21|22|23|24|25|31|32|33|34|35|36|37|38|41|45|47|51|54|55|70|71|72|75|77|76|78|81|91|94)[0-9]{7}$/;

    // Use the regular expression to test the phone number
    return regex.test(phoneNumber);
  }

  function isValidURL(url) {
    // Regular expression to validate URLs
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

    // Use the regular expression to test the URL
    return urlPattern.test(url);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChildData = (data) => {
    // Update the parent component state with data from the child
    setMarkerPosition(data);
  };

  const handleAvatarSelect = (event) => {
    const file = event.target.files[0];
    setSelectedAvatar(file);
  };

  const openSnackbar = (msg) => {
    setSubmitMessage(msg);
    setIsSubmiting(false);
    setOpen(true);
  };

  const submitEvent = () => {
    setIsSubmiting(true);
    if (name === '') {
      openSnackbar('Please Enter a valid name');
    } else if (validateSriLankanPhoneNumber(phone) !== true) {
      openSnackbar('Please Enter a valid phone');
    } else if (facebook && isValidURL(facebook) !== true) {
      openSnackbar('Please Enter a valid facebook url');
    } else if (instagram && isValidURL(instagram) !== true) {
      openSnackbar('Please Enter a valid instagram url');
    } else if (address === '') {
      openSnackbar('Please Enter a valid address');
    } else if (description === '') {
      openSnackbar('Please Enter a valid description');
    } else if (selectedAvatar === null) {
      openSnackbar('Please Select a logo');
    } else if (selectedImage === null) {
      openSnackbar('Please Select a banner image');
    } else if (markerPosition === null) {
      openSnackbar('Please Select a location');
    } else {
      createEvent();
    }
  };

  const generateUniquePath = (prefix) => {
    const uniqueIdentifier = new Date().getTime(); // You can use a different method to generate a unique identifier
    return `${prefix}/${uniqueIdentifier}`;
  };
  const uploadImage = async (folder, name, image) => {
    const uniquePath = generateUniquePath(name);

    const result = await supabase.storage.from(folder).upload(uniquePath, image, {
      // cacheControl: '1',
      upsert: false,
    });
    if (result.error) {
      console.log('network issue', result.error);
      openSnackbar('Some Error occurred. Please try again later.');
      return null;
    }
    return result;
  };

  const createEvent = async () => {
    const user = JSON.parse(localStorage.getItem('User'));

    const logo = await uploadImage('logos', `${name}-`, selectedAvatar);
    const banner = await uploadImage('images', `BannerImage`, selectedImage);

    if (logo && banner) {
      const logoImg = await supabase.storage.from('logos').getPublicUrl(logo.data.path);
      const bannerImg = await supabase.storage.from('images').getPublicUrl(banner.data.path);

      const restaurantData = {
        name,
        address,
        shortDescription: description,
        facebook: facebook !== '' ? facebook : null,
        instagram: instagram !== '' ? instagram : null,
        phone,
        bannerImg: bannerImg.data.publicUrl,
        logo: logoImg.data.publicUrl,
      };

      const restaurant = await supabase.from('Restaurant').insert([restaurantData]).select();

      if (restaurant.error) {
        console.log('network issue', restaurant.error);
        openSnackbar('Some Error occurred. Please try again later.');
        setIsSubmiting(false);
      } else {
        const adminData = {
          role: 'admin',
          restID: restaurant.data[0].id,
          userID: user.id,
        };
        const admin = await supabase.from('RestaurantAdmin').insert([adminData]).select();

        if (admin.error) {
          console.log('network issue', admin.error);
          openSnackbar('Some Error occurred. Please try again later.');
          setIsSubmiting(false);
        } else {
          console.log(admin.data);

          const obj = {
            restID: restaurant.data[0].id,

            lat: markerPosition.lat,
            lng: markerPosition.lng,
          };
          const locationCreate = await supabase.from('Location').insert([obj]).select();

          if (locationCreate.error) {
            console.log('network issue', locationCreate.error);
            openSnackbar('Some Error occurred. Please try again later.');
            setIsSubmiting(false);
          } else {
            await fetch();
            navigate('/pending');
            setIsSubmiting(false);
          }
        }
      }
    }
  };

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleClose}>
        OK
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        x
      </IconButton>
    </>
  );
  return (
    <>
      <Helmet>
        <title> Create Restaurant | FoodieDa </title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Create Restaurant
          </Typography>
          <Card sx={{ p: 4, mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ alignItems: 'center' }}>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="avatar-input"
                    onChange={handleAvatarSelect}
                  />
                  <Avatar
                    alt="Selected Image"
                    src={selectedAvatar ? URL.createObjectURL(selectedAvatar) : '/assets/upload.png'} // Replace with your default avatar image
                    sx={{
                      width: 100,
                      height: 100,
                      border: 1,
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'lightgray', // Change the hover effect as desired
                      },
                    }}
                    onClick={() => {
                      document.getElementById('avatar-input').click();
                    }}
                  />
                  {/* <Typography variant='caption'>Logo</Typography> */}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="image-input"
                    onChange={handleImageSelect}
                  />
                  <Container
                    onClick={() => {
                      document.getElementById('image-input').click();
                    }}
                    sx={{
                      width: 200,
                      height: 100,
                      border: 1,
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'lightgray', // Change the hover effect as desired
                      },
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      overflow: 'hidden', // Hide any overflowing content
                    }}
                  >
                    <img
                      src={selectedImage ? URL.createObjectURL(selectedImage) : '/assets/upload.png'}
                      alt="upload"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </Container>
                  <Typography variant="caption">Banner</Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ my: 1 }}
                  id="name"
                  value={name}
                  fullWidth
                  label="Restaurant Name"
                  variant="outlined"
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ my: 1 }}
                  id="phone"
                  value={phone}
                  fullWidth
                  label="Phone"
                  placeholder="+94xxxxxxxxx"
                  variant="outlined"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ my: 1 }}
                  id="facebook"
                  value={facebook}
                  fullWidth
                  label="Facebook"
                  variant="outlined"
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ my: 1 }}
                  id="instagram"
                  value={instagram}
                  fullWidth
                  label="Instagram"
                  variant="outlined"
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ my: 1 }}
                  id="address"
                  value={address}
                  fullWidth
                  label="Address"
                  multiline
                  maxRows={4}
                  variant="outlined"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ my: 1 }}
                  id="description"
                  value={description}
                  fullWidth
                  label="Description"
                  multiline
                  maxRows={4}
                  variant="outlined"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Map center={markerPosition} height="300px" zoom={8} onDataFromChild={handleChildData} />
              </Grid>
              <Grid item xs={12}>
                {isSubmiting ? (
                  <>
                    <Button variant="contained" fullWidth>
                      <CircularProgress color="inherit" />
                    </Button>
                  </>
                ) : (
                  <>
                    <Button variant="contained" fullWidth onClick={submitEvent}>
                      Create Restaurant
                    </Button>
                  </>
                )}
              </Grid>
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={submitMessage}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              />
            </Grid>
          </Card>
        </StyledContent>
      </Container>
    </>
  );
}
