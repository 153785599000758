import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
// @mui
import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { supabase } from '../supabase/supabase.config';

export default function CreateOfferPage() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('sample message');
  const [offerTypeArr, setOfferTypeArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();

  // const { id } = useParams();
  const admin = JSON.parse(sessionStorage.getItem('Admin'));

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      longDescription: '',
      offerType: '',
      validUntil: '',
      people: '',
      offerPrice: '',
      offerFor: 0,
      minPrice: '',
      maxPrice: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Offer name is required'),
      description: Yup.string(),
      longDescription: Yup.string(),
      offerType: Yup.string(),
      validUntil: Yup.date().required('Offer valid date is required'),
      people: Yup.string(),
      offerPrice: Yup.string(),
      offerFor: Yup.number(),
      minPrice: Yup.string(),
      maxPrice: Yup.string(),
    }),
    onSubmit: (values) => {
      if (selectedImage === null && !image) {
        openSnackbar('Please select a offer image');
      } else if (location.state.id !== null) {
        updateOffer();
      } else {
        CreateOffer();
      }
    },
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await currentDate();
    await getOfferType();

    const { id } = location.state;

    if (id && id !== null) {
      fetch(id);
    }
  };

  const getOfferType = async () => {
    try {
      const { data, error } = await supabase.from('OfferTypes').select(`*`);

      if (error) {
        console.error('Error fetching data:', error);
      } else {
        setOfferTypeArr(data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetch = async (id) => {
    try {
      const { data, error } = await supabase.from('Offers').select(`*`).eq('id', id).single().limit(1);

      if (error) {
        console.error('Error fetching data:', error);
      } else {
        const parts = data.priceRange.split('-');
        console.log(data);
        formik.setValues({
          name: data.name,
          description: data.description ?? '',
          longDescription: data.longDescription ?? '',
          offerType: data.offerType,
          validUntil: formatDate(data.validUntil),
          people: data.people ?? '',
          offerPrice: data.offerPrice ?? '',
          offerFor: data.branchAvailability ? 1 : 0,
          minPrice: parts[0],
          maxPrice: parts[1],
        });

        setImage(data.imgUrl);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const formatDate = (date) => {
    const currentDate = new Date(date);
    const year = currentDate.getFullYear();
    const month = `${currentDate.getMonth() + 1}`.padStart(2, '0'); // Month is zero-based
    const day = `${currentDate.getDate()}`.padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const currentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = `${currentDate.getMonth() + 1}`.padStart(2, '0'); // Month is zero-based
    const day = `${currentDate.getDate()}`.padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    formik.setFieldValue('validUntil', formattedDate);
  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCloseModel = () => {
    navigate('/dashboard/offer');
  };

  const openSnackbar = (msg) => {
    setSubmitMessage(msg);
    setIsLoading(false);
    setOpen(true);
  };
  const uploadImage = async (folder, name, image) => {
    const uniquePath = generateUniquePath(name);
    const result = await supabase.storage.from(folder).upload(uniquePath, image, {
      cacheControl: '3600',
      upsert: false,
    });
    if (result.error) {
      console.log('network issue', result.error);
      openSnackbar('Some Error occurred. Please try again later.');
      setIsLoading(false);
      return null;
    }
    return result;
  };
  const generateUniquePath = (prefix) => {
    const uniqueIdentifier = new Date().getTime(); // You can use a different method to generate a unique identifier
    return `${prefix}/${uniqueIdentifier}`;
  };

  const CreateOffer = async () => {
    const admin = JSON.parse(sessionStorage.getItem('Admin'));
    setIsLoading(true);
    const {
      name,
      description,
      longDescription,
      offerType,
      validUntil,
      people,
      minPrice,
      maxPrice,
      offerFor,
      offerPrice,
    } = formik.values;

    try {
      const offer = await uploadImage('offerImg', `${admin.restID.name}`, selectedImage);
      console.log(offer);

      if (offer !== null) {
        const url = offer.data.path;
        // console.log(offer.data);
        const offerUrl = await supabase.storage.from('offerImg').getPublicUrl(url);

        const data = {
          restID: admin.restID.id,
          name,
          description,
          longDescription,
          offerType,
          imgUrl: offerUrl.data.publicUrl,
          validUntil,
          priceRange: minPrice !== '' && maxPrice !== '' ? `${minPrice}-${maxPrice}` : null,
          loc_point: admin.restID.loc_point,
          branchAvailability: offerFor === 1,
          people: people !== '' ? people : null,
          offerPrice,
        };
        const createOffer = await supabase.from('Offers').insert([data]).select();

        if (createOffer.error) {
          console.log('network issue', createOffer.error);
          openSnackbar('Some Error occurred. Please try again later.');
          setIsLoading(false);
        } else {
          openSnackbar('offer added');
          formik.resetForm();
          handleCloseModel();
          setIsLoading(false);
        }
      } else {
        openSnackbar('Some Error occurred. Please try again later.');
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateOffer = async () => {
    setIsLoading(true);
    const admin = JSON.parse(sessionStorage.getItem('Admin'));
    const {
      name,
      description,
      longDescription,
      offerType,
      validUntil,
      people,
      minPrice,
      maxPrice,
      offerFor,
      offerPrice,
    } = formik.values;

    if (selectedImage) {
      const urlParts = image.split('/');

      // Get the last part (file name)
      const fileName = urlParts[urlParts.length - 1];

      const { data, error } = await supabase.storage.from('offerImg').remove([`${admin.restID.name}/${fileName}`]);

      if (error) {
        console.log('network issue', error);
        openSnackbar('Some Error occurred. Please try again later.');
        setIsLoading(false);
      } else {
        const offer = await uploadImage('offerImg', `${admin.restID.name}`, selectedImage);

        if (offer !== null) {
          const url = offer.data.path;
          // console.log(offer.data);
          const offerUrl = await supabase.storage.from('offerImg').getPublicUrl(url);

          const data = {
            restID: admin.restID.id,
            name,
            description,
            longDescription,
            offerType,
            imgUrl: offerUrl.data.publicUrl,
            validUntil,
            priceRange: minPrice !== '' && maxPrice !== '' ? `${minPrice}-${maxPrice}` : null,
            loc_point: admin.restID.loc_point,
            branchAvailability: offerFor === 1,
            people: people !== '' ? people : null,
            offerPrice,
          };

          const createOffer = await supabase.from('Offers').update(data).eq('id', location.state.id);

          if (createOffer.error) {
            console.log('network issue', createOffer.error);
            openSnackbar('Some Error occurred. Please try again later.');
            setIsLoading(false);
          } else {
            openSnackbar('offer updated');
            handleCloseModel();
            setIsLoading(false);
          }
        } else {
          openSnackbar('Some Error occurred. Please try again later.');
          setIsLoading(false);
        }
      }
    } else {
      const data = {
        name,
        description,
        longDescription,
        offerType,
        validUntil,
        priceRange: minPrice !== '' && maxPrice !== '' ? `${minPrice}-${maxPrice}` : null,
        branchAvailability: offerFor === 1,
        people: people !== '' ? people : null,
        offerPrice,
      };
      const createOffer = await supabase.from('Offers').update(data).eq('id', location.state.id);

      if (createOffer.error) {
        console.log('network issue', createOffer.error);
        openSnackbar('Some Error occurred. Please try again later.');
        setIsLoading(false);
      } else {
        openSnackbar('offer updated');
        handleCloseModel();
        setIsLoading(false);
      }
    }
  };

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleClose}>
        OK
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        x
      </IconButton>
    </>
  );

  return (
    <>
      <Helmet>
        <title> Dashboard: Create-Offer | FoodieDa </title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {location.state.id !== null ? 'Update Offer' : 'Create Offer'}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Card sx={{ p: 4, mt: 2 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <div>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="image-input"
                  onChange={handleImageSelect}
                />
                <Container
                  onClick={() => {
                    document.getElementById('image-input').click();
                  }}
                  sx={{
                    width: 400,
                    height: 300,
                    border: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'lightgray', // Change the hover effect as desired
                    },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden', // Hide any overflowing content
                  }}
                >
                  <img
                    src={
                      selectedImage
                        ? URL.createObjectURL(selectedImage)
                        : image !== undefined
                        ? image
                        : '/assets/addimage.jpg'
                    }
                    // src='/assets/addimage.jpg'
                    alt="upload"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Container>
              </div>

              <Grid container spacing={2} m={4} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12}>
                  <TextField
                    sx={{ my: 1 }}
                    id="name"
                    name="name"
                    required
                    fullWidth
                    label="Name"
                    multiline
                    maxRows={3}
                    variant="outlined"
                    size="small"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ my: 1 }}
                    id="description"
                    name="description"
                    fullWidth
                    label="Description"
                    multiline
                    maxRows={3}
                    variant="outlined"
                    size="small"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReactQuill
                    theme="snow"
                    value={formik.values.longDescription}
                    onChange={(v) => formik.setFieldValue('longDescription', v)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="offerType">Offer Type</InputLabel>
                    <Select
                      labelId="offerType"
                      id="offerType"
                      name="offerType"
                      value={formik.values.offerType}
                      label="Offer Type"
                      onChange={formik.handleChange}
                      size="small"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {offerTypeArr.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    sx={{ my: 1 }}
                    id="validUntil"
                    name="validUntil"
                    required
                    type="date"
                    fullWidth
                    size="small"
                    label="Valid Until"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.validUntil}
                    error={formik.touched.validUntil && Boolean(formik.errors.validUntil)}
                    helperText={formik.touched.validUntil && formik.errors.validUntil}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="people"
                    name="people"
                    fullWidth
                    label="People"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.people}
                    error={formik.touched.people && Boolean(formik.errors.people)}
                    helperText={formik.touched.people && formik.errors.people}
                    type="number"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="offerPrice"
                    name="offerPrice"
                    fullWidth
                    label="Price"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.offerPrice}
                    error={formik.touched.offerPrice && Boolean(formik.errors.offerPrice)}
                    helperText={formik.touched.offerPrice && formik.errors.offerPrice}
                    type="number"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth disabled={admin.restID.isBranch === true}>
                    <InputLabel id="offerFor">Offer For</InputLabel>
                    <Select
                      labelId="offerFor"
                      id="offerFor"
                      name="offerFor"
                      value={formik.values.offerFor}
                      label="Offer For"
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={0}>For this branch</MenuItem>
                      <MenuItem value={1}>For all branch</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="minPrice"
                    name="minPrice"
                    fullWidth
                    label="Min Price"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.minPrice}
                    error={formik.touched.minPrice && Boolean(formik.errors.minPrice)}
                    helperText={formik.touched.minPrice && formik.errors.minPrice}
                    type="number"
                    size="small"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="maxPrice"
                    name="maxPrice"
                    fullWidth
                    label="Max Price"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.maxPrice}
                    error={formik.touched.maxPrice && Boolean(formik.errors.maxPrice)}
                    helperText={formik.touched.maxPrice && formik.errors.maxPrice}
                    type="number"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
              <LoadingButton
                loading={isLoading}
                variant="contained"
                sx={{ marginLeft: 1, marginRight: 4 }}
                size="medium"
                type="submit"
                color="error"
              >
                {location.state.id === null ? 'Create' : 'Update'}
              </LoadingButton>
            </Stack>

            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              message={submitMessage}
              action={action}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
          </Card>
        </form>
      </Container>
    </>
  );
}
