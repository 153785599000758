import LoadingButton from '@mui/lab/LoadingButton';
import { useState, useEffect } from 'react';

// @mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Container,
  Snackbar,
  IconButton,
  TextField,
} from '@mui/material';

import { supabase } from '../../supabase/supabase.config';

export default function GalleryDialog({ closeModel }) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState('');
  const [no, setNo] = useState(null);
  const [submitMessage, setSubmitMessage] = useState('sample message');

  const openSnackbar = (msg) => {
    setSubmitMessage(msg);
    setIsLoading(false);
    setOpen(true);
  };

  const handleCloseModel = () => {
    closeModel(false);
  };
  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const generateUniquePath = (prefix) => {
    const uniqueIdentifier = new Date().getTime(); // You can use a different method to generate a unique identifier
    return `${prefix}/${uniqueIdentifier}`;
  };

  const uploadImage = async (folder, name, image) => {
    const uniquePath = generateUniquePath(name);

    const result = await supabase.storage.from(folder).upload(uniquePath, image, {
      // cacheControl: '1',
      upsert: false,
    });
    if (result.error) {
      console.log('network issue', result.error);
      openSnackbar('Some Error occurred. Please try again later.');
      setIsLoading(false);
      return null;
    }
    return result;
  };

  const createGallery = async () => {
    const admin = JSON.parse(sessionStorage.getItem('Admin'));

    const gallery = await uploadImage('images', `ImageGallery/${admin.restID.name}`, selectedImage);

    if (gallery !== null) {
      console.log(gallery.data);
      const galleryUrl = await supabase.storage.from('images').getPublicUrl(gallery.data.path);

      const data = {
        restID: admin.restID.id,
        description,
        url: galleryUrl.data.publicUrl,
        addedBy: admin.userID,
      };

      const create = await supabase.from('ImageGallery').insert([data]).select();

      if (create.error) {
        console.log('network issue', create.error);
        openSnackbar('Some Error occurred. Please try again later.');
        setIsLoading(false);
      } else {
        openSnackbar('Gallery added');
        handleCloseModel();
        setIsLoading(false);
      }
    } else {
      openSnackbar('Some Error occurred. Please try again later.');
      setIsLoading(false);
    }
  };

  const handleAdd = () => {
    setIsLoading(true);
    if (selectedImage === null) {
      openSnackbar('Please Select a banner image');
    } else if (description === '') {
      openSnackbar('Please Enter a page number');
    } else {
      createGallery();
    }
  };

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleCloseModel}>
        OK
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseModel}>
        x
      </IconButton>
    </>
  );

  return (
    <>
      <Dialog open={closeModel} onClose={handleCloseModel} aria-labelledby="category-form" fullWidth>
        <DialogTitle id="category-form">Gallery</DialogTitle>

        <DialogContent>
        <DialogContentText>Select the image and description below</DialogContentText>

          <Stack direction="row" spacing={2} mt={2} alignItems="center" justifyContent="space-evenly">
            <div>
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                id="image-input"
                onChange={handleImageSelect}
              />
              <Container
                onClick={() => {
                  document.getElementById('image-input').click();
                }}
                sx={{
                  width: 200,
                  height: 200,
                  border: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'lightgray', // Change the hover effect as desired
                  },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden', // Hide any overflowing content
                }}
              >
                <img
                  src={
                    selectedImage ? URL.createObjectURL(selectedImage) : image !== null ? image : '/assets/upload.png'
                  }
                  alt="upload"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Container>
            </div>
            <TextField
              sx={{ my: 1 }}
              id="description"
              required
              value={description}
              fullWidth
              label="Description"
              multiline
              maxRows={4}
              variant="outlined"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModel} color="secondary">
            Cancel
          </Button>
          {isLoading ? (
            <LoadingButton loading variant="outlined">
              Submit
            </LoadingButton>
          ) : (
            <Button onClick={handleAdd} color="primary">
              Create
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={submitMessage}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </>
  );
}
