import { Icon } from '@iconify/react';
import { Grid, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from '@mui/material';
import React, { useState , useEffect} from 'react';
import { Outlet, useNavigate,useLocation } from 'react-router-dom';
import RestaurantIcon from '@mui/icons-material/Restaurant';

function Settings() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState('restaurant');
  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
    navigate(`/dashboard/settings/${menuItem}`);
  };

  useEffect(() => {
    const { pathname } = location;
    let state = '';
    if (pathname.includes('/restaurant')) {
      state = 'restaurant';
    } else if (pathname.includes('/branch')) {
      state = 'branch';
    }
    setSelectedMenuItem(state);
  }, [location]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Settings</Typography>
        </Grid>
        <Grid item xs={3} sx={{ p: 2 }}>
          <MenuList>
            <MenuItem
              selected={selectedMenuItem === 'restaurant'}
              onClick={() => handleMenuItemClick('restaurant')}
              sx={{
                backgroundColor: selectedMenuItem === 'restaurant' ? '#F9FAFB' : 'transparent',
              }}
            >
              <ListItemIcon>
                <RestaurantIcon />
              </ListItemIcon>
              <ListItemText>Restaurant</ListItemText>
            </MenuItem>

            <MenuItem
              selected={selectedMenuItem === 'branch'}
              onClick={() => handleMenuItemClick('branch')}
              sx={{
                backgroundColor: selectedMenuItem === 'branch' ? '#F9FAFB' : 'transparent',
              }}
            >
              <ListItemIcon>
              <Icon icon="gravity-ui:branches-right-arrow-right" />
              </ListItemIcon>
              <ListItemText>Branch</ListItemText>
            </MenuItem>
          </MenuList>
        </Grid>
        <Grid xs={9}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
}

export default Settings;
