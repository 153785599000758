import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Card, Container, Grid, Stack, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { supabase } from '../supabase/supabase.config';
import Label from '../components/label';
import { fDate } from '../utils/formatTime';
import { DeleteDialog } from '../components/Dialog';

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

export default function OfferPage() {
  const [offer, setOffer] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [id, setId] = useState();
  const [url, setUrl] = useState();
  const admin = JSON.parse(sessionStorage.getItem('Admin'));
  const navigate = useNavigate();

  useEffect(() => {
    fetch();
  }, [openDelete]);

  const fetch = async () => {
    try {
      const { data, error } = await supabase.from('Offers').select(`*`).eq('restID', admin.restID.id);

      if (error) {
        console.error('Error fetching data:', error);
      } else {
        setOffer(data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleOpen = () => {
    navigate(`/dashboard/create-offer`, { state: { id: null } });
  };

  const handleUpdate = (id) => {
    navigate(`/dashboard/create-offer`, { state: { id } });
  };

  const handleDeleteOpen = (url, id) => {
    setUrl(url);
    setId(id);
    setOpenDelete(true);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Offer | FoodieDa </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Offer
        </Typography>
        <Grid container spacing={3}>
          {offer &&
            offer.length > 0 &&
            offer.map((offer) => (
              <Grid key={offer.id} item xs={12} sm={6} md={3}>
                <Card>
                  <Box sx={{ pt: '100%', position: 'relative' }}>
                    <Label
                      variant="filled"
                      color={'info'}
                      sx={{
                        zIndex: 9,
                        top: 16,
                        right: 16,
                        position: 'absolute',
                        textTransform: 'uppercase',
                      }}
                    >
                      {fDate(offer.validUntil)}
                    </Label>

                    <StyledProductImg alt={'name'} src={offer.imgUrl} />
                  </Box>

                  <Stack spacing={2} sx={{ p: 3 }}>
                    <Typography variant="subtitle2" noWrap>
                      {offer.name}
                    </Typography>

                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="caption">{offer?.description}</Typography>
                      <Typography variant="caption">{`Rs ${offer.priceRange ?? ''}`}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <IconButton aria-label="delete" color="primary" onClick={() => handleUpdate(offer.id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteOpen(offer.imgUrl, offer.name)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            ))}

          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ cursor: 'pointer' }} onClick={handleOpen}>
              <Box sx={{ pt: '100%', position: 'relative' }}>
                <StyledProductImg alt="name" src="/assets/addimage.jpg" />
              </Box>

              <Stack spacing={2} sx={{ p: 3 }}>
                <Typography variant="subtitle2" noWrap>
                  {admin.restID.isBranch ? 'Add offer to this branch' : ' Click to add offer'}
                </Typography>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {openDelete && <DeleteDialog closeModel={setOpenDelete} mode={'offer'} id={id} url={url} />}
    </>
  );
}
