import { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
// @mui
import {
  Box,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import { supabase } from '../supabase/supabase.config';

export default function FeaturePage() {
  const [ac, setAc] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [veg, setVeg] = useState(false);
  const [dineIn, setDineIn] = useState(false);
  const [petFriendly, setPetFriendly] = useState(false);
  const [parking, setParking] = useState(false);
  const [wifi, setWifi] = useState(false);
  const [payment, setPayment] = useState({
    Cash: false,
    Card: false,
  });

  const [feaure, setFeature] = useState();
  const admin = JSON.parse(sessionStorage.getItem('Admin'));

  useEffect(() => {
    fetch();
  }, []);

  const fetureAdd = async (ac, delivery, veg, dineIn, petFriendly, parking, wifi, payment) => {
    if (!feaure) {
      try {
        const body = {
          restID: admin.restID.id,
          airCondition: ac,
          delivery,
          vegitarian: veg,
          dineIn,
          petFriendly,
          parking,
          wifi,
          payment,
        };

        const { data, error } = await supabase.from('Fetures').insert([body]).select();

        if (error) {
          console.error('Error fetching data:', error);
        } else {
          fetch();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      try {
        const body = {
          restID: admin.restID.id,
          airCondition: ac,
          delivery,
          vegitarian: veg,
          dineIn,
          petFriendly,
          parking,
          wifi,
          payment,
        };
        const { data, error } = await supabase.from('Fetures').update(body).eq('restID', admin.restID.id).select();

        if (error) {
          console.error('Error fetching data:', error);
        } else {
          fetch();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const fetch = async () => {
    try {
      const { data, error } = await supabase
        .from('Fetures')
        .select(`*`)
        .eq('restID', admin.restID.id)
        .single()
        .limit(1);

      if (error) {
        console.error('Error fetching data:', error);
      } else {
        setFeature(data);
        setAc(data.airCondition ?? false);
        setDelivery(data.delivery ?? false);
        setVeg(data.vegitarian ?? false);
        setDineIn(data.dineIn ?? false);
        setPetFriendly(data.petFriendly ?? false);
        setParking(data.parking ?? false);
        setWifi(data.wifi ?? false);
        setPayment({
          Cash: data.payment !== null && data.payment.includes('Cash'),
          Card: data.payment !== null && data.payment.includes('Card'),
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleACChange = () => {
    const updatedAc = !ac;
    setAc(updatedAc);
    const pay = createArrayFromObject(payment);
    fetureAdd(updatedAc, delivery, veg, dineIn, petFriendly, parking, wifi, pay);
  };

  const handleDeliveryChange = () => {
    const updated = !delivery;
    setDelivery(updated);
    const pay = createArrayFromObject(payment);
    fetureAdd(ac, updated, veg, dineIn, petFriendly, parking, wifi, pay);
  };

  const handleVegChange = () => {
    const updated = !veg;
    setVeg(updated);
    const pay = createArrayFromObject(payment);
    fetureAdd(ac, delivery, updated, dineIn, petFriendly, parking, wifi, pay);
  };

  const handleDineChange = () => {
    const updated = !dineIn;
    setDineIn(updated);
    const pay = createArrayFromObject(payment);
    fetureAdd(ac, delivery, veg, updated, petFriendly, parking, wifi, pay);
  };

  const handleWifiChange = () => {
    const updated = !wifi;
    setWifi(updated);
    const pay = createArrayFromObject(payment);
    fetureAdd(ac, delivery, veg, dineIn, petFriendly, parking, updated, pay);
  };

  const handlePetChange = () => {
    const updated = !petFriendly;
    setPetFriendly(updated);
    const pay = createArrayFromObject(payment);
    fetureAdd(ac, delivery, veg, dineIn, updated, parking, wifi, pay);
  };
  const handleParkingChange = () => {
    const updated = !parking;
    setParking(updated);
    const pay = createArrayFromObject(payment);
    fetureAdd(ac, delivery, veg, dineIn, petFriendly, updated, wifi, pay);
  };

  function createArrayFromObject(obj) {
    // Filter the keys based on the values being true
    return Object.keys(obj).filter((key) => obj[key] === true);
  }

  const handleChange1 = (event) => {
    const obj = { Cash: event.target.checked, Card: event.target.checked };
    setPayment(obj);

    const pay = createArrayFromObject(obj);

    fetureAdd(ac, delivery, veg, dineIn, petFriendly, parking, wifi, pay);
  };

  const handleChange2 = (event) => {
    const obj = { Cash: event.target.checked, Card: payment.Card };
    setPayment(obj);

    const pay = createArrayFromObject(obj);

    fetureAdd(ac, delivery, veg, dineIn, petFriendly, parking, wifi, pay);
  };

  const handleChange3 = (event) => {
    const obj = { Cash: payment.Cash, Card: event.target.checked };
    setPayment(obj);

    const pay = createArrayFromObject(obj);
    console.log(pay);

    fetureAdd(ac, delivery, veg, dineIn, petFriendly, parking, wifi, pay);
  };

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel label="Cash" control={<Checkbox checked={payment.Cash} onChange={handleChange2} />} />
      <FormControlLabel label="Card" control={<Checkbox checked={payment.Card} onChange={handleChange3} />} />
    </Box>
  );
  return (
    <>
      <Helmet>
        <title> Dashboard: Features | FoodieDa </title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Features
        </Typography>

        <Card sx={{ p: 4, mt: 2 }}>
          <FormControlLabel
            sx={{ backgroundColor: '#acddde', p: 1, pr: 2, borderRadius: 1, m: 2 }}
            label="A/C"
            control={<Switch checked={ac} onChange={handleACChange} />}
          />
          <FormControlLabel
            sx={{ backgroundColor: '#ffe7c7', p: 1, pr: 2, borderRadius: 1, m: 2 }}
            label="Delivery"
            control={<Switch checked={delivery} onChange={handleDeliveryChange} />}
          />
          <FormControlLabel
            sx={{ backgroundColor: '#caf1de', p: 1, pr: 2, borderRadius: 1, m: 2 }}
            label="Vegetarian"
            control={<Switch checked={veg} onChange={handleVegChange} />}
          />
          <FormControlLabel
            sx={{ backgroundColor: '#e8a09a', p: 1, pr: 2, borderRadius: 1, m: 2 }}
            label="Dine In"
            control={<Switch checked={dineIn} onChange={handleDineChange} />}
          />
          <FormControlLabel
            sx={{ backgroundColor: '#f7d8ba', p: 1, pr: 2, borderRadius: 1, m: 2 }}
            label="Pet Friendly"
            control={<Switch checked={petFriendly} onChange={handlePetChange} />}
          />
          <FormControlLabel
            sx={{ backgroundColor: '#17a77f', p: 1, pr: 2, borderRadius: 1, m: 2 }}
            label="Parking"
            control={<Switch checked={parking} onChange={handleParkingChange} />}
          />
          <FormControlLabel
            sx={{ backgroundColor: '#eff8ff', p: 1, pr: 2, borderRadius: 1, m: 2 }}
            label="Wifi"
            control={<Switch checked={wifi} onChange={handleWifiChange} />}
          />
          <Stack direction={'column'} m={2} sx={{ backgroundColor: '#95cdea', p: 1, borderRadius: 1, width: 300 }}>
            <FormControlLabel
              label="Payments"
              control={
                <Checkbox
                  checked={payment.Cash && payment.Card}
                  indeterminate={payment.Cash !== payment.Card}
                  onChange={handleChange1}
                />
              }
            />
            {children}
          </Stack>
        </Card>
      </Container>
    </>
  );
}
