import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { GoogleMap, LoadScript, Marker, InfoWindow, Autocomplete } from '@react-google-maps/api';

const apiKey = process.env.REACT_APP_GOOGLE;

const Map = ({ center, zoom, onDataFromChild }) => {
  const [address, setAddress] = useState('');
  const [markerPosition, setMarkerPosition] = useState({
    lat: center.lat,
    lng: center.lng,
  });

  //   useEffect(() => {
  //    setKey(apiKey);
  //   }, []);

  //   useEffect(() => {
  //     if (markerPosition.lat && markerPosition.lng) {
  //      fromLatLng(markerPosition.lat, markerPosition.lng)
  //         .then((response) => {
  //           setAddress(response.results[0].formatted_address);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //     }
  //   }, [markerPosition]);

  //   const onPlaceSelected = async (place) => {
  //     const address = place.formatted_address;

  //     try {
  //       const response = await fromAddress(address);
  //       const { lat, lng } = response.results[0].geometry.location;

  //       setAddress(address);
  //       setMarkerPosition({ lat, lng });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  const onMarkerDragEnd = async (event) => {
    const evenLatLng = JSON.stringify(event.latLng);

    const { lat, lng } = JSON.parse(evenLatLng);

    try {
      //   const response = await fromLatLng(lat, lng);
      //   const address = response.results[0].formatted_address;
      //   setAddress(address);
      setMarkerPosition({ lat, lng });
      onDataFromChild({ lat, lng });
      //   console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <LoadScript googleMapsApiKey={apiKey}>
            <GoogleMap
              zoom={zoom}
              center={markerPosition}
              mapContainerStyle={{ width: '100%', height: '400px' }}
              onClick={(event) => {
                const evenLatLng = JSON.stringify(event.latLng);

                const { lat, lng } = JSON.parse(evenLatLng);
                setMarkerPosition({ lat, lng });
                // Send the clicked coordinates to the parent component if needed
                onDataFromChild({ lat, lng });
              }}
            >
              {/* <Autocomplete
                style={{ width: "100%", height: "40px", paddingLeft: "16px" }}
                onPlaceSelected={onPlaceSelected}
                types={["(regions)"]}
              /> */}
              <Marker draggable onDragEnd={onMarkerDragEnd} position={markerPosition} />
              {/* <InfoWindow
                onCloseClick={() => {}}
                position={{
                  lat: markerPosition.lat + 0.0018,
                  lng: markerPosition.lng,
                }}
              >
                <Box sx={{ padding: 0, margin: 0 }}>
                  <Typography variant="body2">{address}</Typography>
                </Box>
              </InfoWindow> */}
            </GoogleMap>
          </LoadScript>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Map;
