import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import { Button, Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';
import Logo from '../components/logo';
// sections
import { supabase } from '../supabase/supabase.config';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = supabase.auth.onAuthStateChange((event, session) => {
          switch (event) {
            case 'SIGNED_OUT':
              setUser(null);
              localStorage.removeItem('User');
              break;
            case 'SIGNED_IN':
              setUser(session?.user);
              localStorage.setItem('User', JSON.stringify(session?.user));
              navigate('/create-restaurant');
              break;
            default:
          }
        });
      } catch (error) {
        console.error('Error fetching session:', error);
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, []); // Empty dependency array for running the effect once on mount

  const Login = async () => {
    try {
      console.log(window.location.origin);
      const { user, session, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: { redirectTo: window.location.origin },
      });

      if (user) {
        // User is signed in.
        console.log('User signed in:', user);
        setUser(user);
        // You can access user information like user.id, user.email, etc.
      }

      if (error) {
        // Handle the error if the sign-in fails.
        console.error('Error signing in:', error);
      }
    } catch (error) {
      // Handle any unexpected errors.
      console.error('Unexpected error:', error);
    }
  };
  const Logout = async () => {
    try {
      const { user, error } = await supabase.auth.signOut();

      if (user) {
        // User is signed in.
        console.log('User signed in:', user);
        setUser(user);
        // You can access user information like user.id, user.email, etc.
      }

      if (error) {
        // Handle the error if the sign-in fails.
        console.error('Error signing in:', error);
      }
    } catch (error) {
      // Handle any unexpected errors.
      console.error('Unexpected error:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Login | FoodieDa </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in to FoodieDa
            </Typography>

            <Stack direction="row" spacing={2} sx={{ my: 3 }}>
              <Button fullWidth size="large" color="inherit" variant="outlined" onClick={Login}>
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
              </Button>
            </Stack>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
