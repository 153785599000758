import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, IconButton, Typography } from '@mui/material';

export default function ReportFinishedDialog({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };
  

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          color="error"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: '#d32f2f' }} />
        </IconButton>

        <Box
          m={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box component={'img'} src="/assets/report.png" alt="report" />
          <Typography variant="body2">This Review Has Been Reported</Typography>
          <Typography mt={1} variant="caption" color={'grey'}>
            Thanks For Helping Make Reviews Better
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
