import { Icon } from '@iconify/react';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import {
  Avatar,
  Card,
  Checkbox,
  Grid,
  TextField,
  Rating,
  Stack,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';

import { useTheme, createTheme, ThemeProvider, alpha, getContrastRatio } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';

import { supabase } from '../../supabase/supabase.config';
import ReportReviewDialog from '../report/ReportReviewDialog';
import ReportFinishedDialog from '../report/ReportFinishedDialog';
import DeleteReviewDialog from '../report/DeleteReplyDialog';

export default function ReviewCard({ data, get }) {
  const [liked, setLiked] = useState(data.liked ?? []);
  const [disliked, setDisliked] = useState(data.disliked ?? []);
  const [reply, setReply] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [reportSucessOpen, setReportSucessOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const violetBase = '#DD1022';
  const violetMain = alpha(violetBase, 0.7);

  const themes = createTheme({
    palette: {
      button: {
        main: violetMain,
        light: alpha(violetBase, 0.5),
        dark: alpha(violetBase, 0.9),
        contrastText: getContrastRatio(violetMain, '#fff') > 4.5 ? '#fff' : '#111',
      },
    },
  });

  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down('sm'));

  const admin = JSON.parse(sessionStorage.getItem('Admin'));

  useEffect(() => {
    setLiked(data.liked ?? []);
    setDisliked(data.disliked ?? []);
  }, [data]);

  const handleClickOpen = () => {
    setDeleteOpen(true);
  };

  const handleClickOpenReportSucess = () => {
    setReportSucessOpen(true);
  };

  const handleEdit = (key, value) => {
    setReply({
      ...reply,
      [key]: value,
    });
  };

  const handleChange = (key, event) => {
    const { value } = event.target;
    setReply({
      ...reply,
      [key]: value,
    });
  };

  const handleCancel = (key) => {
    setReply({
      ...reply,
      [key]: '',
    });
  };

  const ratings = {
    5: 'Excellent',
    4: 'Good',
    3: 'Average',
    2: 'Below Average',
    1: 'Poor',
  };

  const replyReview = async (id) => {
    setIsLoading(true);
    try {
      const { error } = await supabase.from('Review').update({ reply: reply[id] }).eq('id', id);

      if (error) {
        console.error('Error update data:', error);
        setIsLoading(false);
      } else {
        get(0, 9, true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleClickOpenReport = () => {
    setReportOpen(true);
  };

  return (
    <ThemeProvider theme={themes}>
      <Card variant="outlined" sx={{ borderRadius: 3, p: 2, pb: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={1.2}>
            {match ? (
              <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                <Avatar alt={data.userID.full_name} src={data.userID.avatar_url} sx={{ width: 50, height: 50 }} />

                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-around'}>
                  {!data.Report.some((item) => item.reportedBy === admin.userID) && (
                    <IconButton
                      aria-label="report"
                      size="small"
                    sx={{ width: 25, height: 25 }}
                      onClick={handleClickOpenReport}
                    >
                      <Icon icon="ic:round-report-gmailerrorred" style={{ color: 'grey' }} />
                    </IconButton>
                  )}
                </Stack>
              </Stack>
            ) : (
              <Avatar alt={data.userID.full_name} src={data.userID.avatar_url} sx={{ width: 50, height: 50 }} />
            )}
          </Grid>
          <Grid item xs={12} md={10.8}>
            <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
              <Stack direction={'column'} alignContent={'center'} justifyContent={'space-between'}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {data.userID.full_name}
                </Typography>
                <Rating
                  name="simple-controlled"
                  size="small"
                  value={data.rating}
                  readOnly
                  icon={<StarRoundedIcon fontSize="inherit" />}
                  emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
                  sx={{ my: 0.5 }}
                />
                <Typography variant="caption" sx={{ fontWeight: 'bold', color: '#DD1022', my: 0.5 }}>
                  {ratings[data.rating] || ''}
                </Typography>
              </Stack>

              {!match && (
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-around'}>
                  {!data.Report.some((item) => item.reportedBy === admin.userID) && (
                    <IconButton
                      aria-label="report"
                      size="small"
                      sx={{ width: 25, height: 25 }}
                      onClick={handleClickOpenReport}
                    >
                      <Icon icon="ic:round-report-gmailerrorred" style={{ color: 'grey' }} />
                    </IconButton>
                  )}
                </Stack>
              )}
            </Stack>

            <Typography variant="caption" mt={1}>
              {data.comment}
            </Typography>

            <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
              <Checkbox
                icon={<Icon icon="solar:like-broken" style={{ color: '#faaf00' }} />}
                checkedIcon={<Icon icon="solar:like-bold" style={{ color: '#faaf00' }} />}
                disabled
                checked={liked !== null && liked.length !== 0}
                //   onChange={like}
              />

              <Typography variant="caption" color="#faaf00" ml={-1}>
                {liked !== null && liked.length !== 0 ? liked.length : ''}
              </Typography>

              <Checkbox
                icon={<Icon icon="solar:dislike-broken" style={{ color: '#797979' }} />}
                checkedIcon={<Icon icon="solar:dislike-bold" style={{ color: '#797979' }} />}
                disabled
                checked={disliked !== null && disliked.length !== 0}
                //   onChange={dislike}
              />
              <Typography variant="caption" color="#797979" ml={-1}>
                {disliked !== null && disliked.length !== 0 ? disliked.length : ''}
              </Typography>
            </Stack>

            {data.reply !== null && (reply[data.id] === '' || reply[data.id] === undefined) ? (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                  <Avatar alt={admin.restID.name} src={admin.restID.logo} sx={{ width: 30, height: 30 }} />

                  <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'space-between'} ml={2}>
                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                      {admin.restID.name}
                    </Typography>
                    <Typography variant="caption">{data.reply}</Typography>
                  </Stack>
                </Stack>

                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-around'}>
                  <Tooltip title="Delete Reply">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      sx={{ width: 25, height: 25 }}
                      onClick={handleClickOpen}
                    >
                      <Icon icon="fluent:delete-20-regular" style={{ color: '#DD1022' }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Edit Reply">
                    <IconButton
                      aria-label="edit"
                      size="small"
                      sx={{ width: 25, height: 25 }}
                      onClick={() => handleEdit(data.id, data.reply)}
                    >
                      <Icon icon="bx:edit" style={{ color: '#DD1022' }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            ) : (
              <>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                  <Avatar alt={admin.restID.name} src={admin.restID.logo} sx={{ width: 30, height: 30 }} />
                  <TextField
                    id="standard-basic"
                    fullWidth
                    value={reply[data.id]}
                    onChange={(e) => handleChange(data.id, e)}
                    size="small"
                    placeholder="Add A Reply..."
                    variant="standard"
                    sx={{ ml: 2 }}
                  />
                </Stack>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} mt={2}>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Button
                      variant="text"
                      size="small"
                      sx={{ borderRadius: 2 }}
                      color="inherit"
                      onClick={() => handleCancel(data.id)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ borderRadius: 5, ml: 2 }}
                      color="button"
                      disabled={Object.keys(reply).length === 0 || reply[data.id] === '' || isLoading}
                      onClick={() => replyReview(data.id)}
                    >
                      Reply
                    </Button>
                  </Stack>
                </Stack>
              </>
            )}
          </Grid>
        </Grid>
        <ReportReviewDialog
          open={reportOpen}
          setOpen={setReportOpen}
          get={get}
          id={data.id}
          sucess={handleClickOpenReportSucess}
        />
        <ReportFinishedDialog open={reportSucessOpen} setOpen={setReportSucessOpen} />
        <DeleteReviewDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        get={get}
        id={data.id}
      />
      </Card>
    </ThemeProvider>
  );
}
