import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

// const containerStyle = {
//   width: '100%',
//   height: '300px',
// };

// const center = {
//   lat: 9.661451499519952,
//   lng: 80.02569546699601,
// };

function Maps({ containerStyle, center, zoom, onDataFromChild }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBX9ezbTGcggBrOclrTT82jNprRhdloB1A',
  });

  const [map, setMap] = React.useState(null);

  const [markerPosition, setMarkerPosition] = React.useState({
    lat: center.lat,
    lng: center.lng,
  });

  const onMarkerDragEnd = async (event) => {
    const evenLatLng = JSON.stringify(event.latLng);

    const { lat, lng } = JSON.parse(evenLatLng);

    try {
      //   const response = await fromLatLng(lat, lng);
      //   const address = response.results[0].formatted_address;
      //   setAddress(address);
      setMarkerPosition({ lat, lng });
      onDataFromChild({ lat, lng });
      //   console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const onLoad = React.useCallback((map) => {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={markerPosition}
      zoom={14}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={(event) => {
        const evenLatLng = JSON.stringify(event.latLng);

        const { lat, lng } = JSON.parse(evenLatLng);
        setMarkerPosition({ lat, lng });
        // Send the clicked coordinates to the parent component if needed
        onDataFromChild({ lat, lng });
      }}
    >
      <Marker draggable onDragEnd={onMarkerDragEnd} position={markerPosition} />
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(Maps);
