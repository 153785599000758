import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Card, Container, Grid, Stack, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { supabase } from '../supabase/supabase.config';
import { MenuDialog, DeleteDialog } from '../components/Dialog';

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

export default function MenuPage() {
  const [menu, setMenu] = useState([]);
  const admin = JSON.parse(sessionStorage.getItem('Admin'));
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [pageNO, setPageNO] = useState([]);
  const [url, setUrl] = useState();
  const [mode, setMode] = useState('create');
  const [id, setId] = useState();

  useEffect(() => {
    fetch();
  }, [open, openDelete]);

  const fetch = async () => {
    try {
      const { data, error } = await supabase.from('MenuImage').select(`*`).eq('restID', admin.restID.id);

      if (error) {
        console.error('Error fetching data:', error);
      } else {
        const arr = data.sort((a, b) => a.page_no - b.page_no);
        const page = data.map((item) => item.page_no);
        setPageNO(page);
        setMenu(arr);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleOpen = (mode, id) => {
    setMode(mode);
    if (mode === 'update') {
      setId(id);
    }
    setOpen(true);
  };
  const handleDeleteOpen = (url, id) => {
    setUrl(url);
    setId(id);
    setOpenDelete(true);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Menu | FoodieDa </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Menu
        </Typography>
        <Grid container spacing={3}>
          {menu &&
            menu.length > 0 &&
            menu.map((menu) => (
              <Grid key={menu.id} item xs={12} sm={6} md={3}>
                <Card>
                  <Box sx={{ pt: '100%', position: 'relative' }}>
                    <StyledProductImg alt="name" src={menu.url} />
                  </Box>

                  <Stack spacing={2} sx={{ p: 3 }}>
                    <Typography variant="subtitle2" noWrap>
                      {menu.page_no}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <IconButton aria-label="delete" color="primary" onClick={() => handleOpen('update', menu.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" color="error" onClick={() => handleDeleteOpen(menu.url, menu.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </Card>
              </Grid>
            ))}

          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ cursor: 'pointer' }} onClick={() => handleOpen('create', null)}>
              <Box sx={{ pt: '100%', position: 'relative' }}>
                <StyledProductImg alt="name" src="/assets/addimage.jpg" />
              </Box>

              <Stack spacing={2} sx={{ p: 3 }}>
                <Typography variant="subtitle2" noWrap>
                  {admin.restID.isBranch ? 'Add menu to this branch' : 'Click to add menu'}
                </Typography>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {open && <MenuDialog closeModel={setOpen} page={pageNO} mode={mode} id={id} />}
      {openDelete && <DeleteDialog closeModel={setOpenDelete} mode={'menu'} id={id} url={url} />}
    </>
  );
}
