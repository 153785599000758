import { useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../../supabase/supabase.config';
// mocks_
import account from '../../../_mock/account';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   icon: 'eva:home-fill',
  //   name:"home"
  // },
  // {
  //   label: 'Profile',
  //   icon: 'eva:person-fill',
  //   name:"profile"

  // },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    name: 'settings',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    let user = localStorage.getItem('User');
    if (user) {
      user = JSON.parse(user);
      setUser(user);
    }
  }, []);

  const handleCilck = (name) => {
    console.log(name)
    navigate(`/dashboard/${name}`);
    handleClose()
  };

  const Logout = async () => {
    try {
      const { user, error } = await supabase.auth.signOut();
      console.log(error, 'here');
      if (user) {
        // User is signed in.
        console.log('User signed in:', user);

        // You can access user information like user.id, user.email, etc.
      }

      if (error) {
        // Handle the error if the sign-in fails.
        console.error('Error signing in:', error);
      }
    } catch (error) {
      // Handle any unexpected errors.
      console.error('Unexpected error:', error);
    }

    setOpen(null);
    localStorage.clear();
    navigate('/login');
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={user?.user_metadata?.avatar_url} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.user_metadata?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.user_metadata?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={()=> handleCilck(option.name)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={Logout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
