import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';

// @mui
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Snackbar } from '@mui/material';

import { supabase } from '../../supabase/supabase.config';

export default function DeleteDialog({ closeModel, mode, id, url }) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('sample message');

  const handleCloseModel = () => {
    closeModel(false);
  };

  const deleteMenu = async () => {
    const admin = JSON.parse(sessionStorage.getItem('Admin'));
    const urlParts = url.split('/');

    // Get the last part (file name)
    const fileName = urlParts[urlParts.length - 1];
    setIsLoading(true);
    try {
      const { error } = await supabase.from('MenuImage').delete().eq('id', id);

      if (error) {
        console.log('network issue', error);
        openSnackbar('Some Error occurred. Please try again later.');
        setIsLoading(false);
      } else {
        const { data, error } = await supabase.storage
          .from('images')
          .remove([`MenuImages/${admin.restID.name}/${fileName}`]);

        if (error) {
          console.log('network issue', error);
          openSnackbar('Some Error occurred. Please try again later.');
          setIsLoading(false);
        } else {
          handleCloseModel();
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error('Error delete data:', error);
      setIsLoading(false);
    }
  };
  const deleteOffer = async () => {
    const admin = JSON.parse(sessionStorage.getItem('Admin'));
    const urlParts = url.split('/');

    // Get the last part (file name)
    const fileName = urlParts[urlParts.length - 1];
    setIsLoading(true);
    try {
      const { error } = await supabase.from('Offers').delete().eq('name', id);

      if (error) {
        console.log('network issue', error);
        openSnackbar('Some Error occurred. Please try again later.');
        setIsLoading(false);
      } else {
        const { data, error } = await supabase.storage.from('offerImg').remove([`${admin.restID.name}/${fileName}`]);

        if (error) {
          console.log('network issue', error);
          openSnackbar('Some Error occurred. Please try again later.');
          setIsLoading(false);
        } else {
          handleCloseModel();
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error('Error delete data:', error);
      setIsLoading(false);
    }
  };

  const deleteGallery = async () => {
    const admin = JSON.parse(sessionStorage.getItem('Admin'));
    const urlParts = url.split('/');

    // Get the last part (file name)
    const fileName = urlParts[urlParts.length - 1];
    setIsLoading(true);
    try {
      const { error } = await supabase.from('ImageGallery').delete().eq('id', id);

      if (error) {
        console.log('network issue', error);
        openSnackbar('Some Error occurred. Please try again later.');
        setIsLoading(false);
      } else {
        const { data, error } = await supabase.storage
          .from('images')
          .remove([`ImageGallery/${admin.restID.name}/${fileName}`]);

        if (error) {
          console.log('network issue', error);
          openSnackbar('Some Error occurred. Please try again later.');
          setIsLoading(false);
        } else {
          handleCloseModel();
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error('Error delete data:', error);
      setIsLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleCloseModel}>
        OK
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseModel}>
        x
      </IconButton>
    </>
  );
  const openSnackbar = (msg) => {
    setSubmitMessage(msg);
    setIsLoading(false);
    setOpen(true);
  };

  return (
    <>
      <Dialog open={closeModel} onClose={handleCloseModel} aria-labelledby="category-form" fullWidth>
        <DialogTitle id="category-form">Do you want to delete the {mode}?</DialogTitle>

        <DialogActions>
          <Button onClick={handleCloseModel} color="secondary">
            Cancel
          </Button>
          {isLoading ? (
            <LoadingButton loading variant="outlined">
              Submit
            </LoadingButton>
          ) : (
            <Button
              onClick={() => (mode === 'menu' ? deleteMenu() : mode === 'offer' ? deleteOffer() : deleteGallery())}
              color="primary"
            >
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={submitMessage}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </>
  );
}
