import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton } from '@mui/material';
import { useState } from 'react';
import { supabase } from '../../supabase/supabase.config';

export default function DeleteReviewDialog({ open, setOpen, get, id }) {
  const [loading, setLoading] = useState(false);

  const deleteRate = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.from('Review').update({ reply: null }).eq('id', id);

      if (error) {
        setLoading(false);
        console.error('Error update data:', error);
      } else {
        get(0, 9, true);
        setLoading(false);
        setOpen(false);
      }
    } catch (error) {
      console.error('Error update data:', error);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      {/* <DialogTitle>Are you sure You want to delete this review?</DialogTitle> */}
      <DialogContent sx={{ height: 150 }}>
        <DialogContentText mt={4} textAlign={'center'} fontSize={20}>
          Are you sure You want to delete Your review?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          mb: 4,
        }}
      >
        <Button
          variant="contained"
          color="error"
          size="small"
          startIcon={<DeleteIcon />}
          disabled={loading}
          onClick={deleteRate}
        >
          Delete
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          startIcon={<HighlightOffTwoToneIcon />}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
