
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";

import { supabase } from '../../supabase/supabase.config';

export default function ReportReviewDialog({
    open,
    setOpen,
    get,
    id,
    sucess,
  }) {
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState([]);
    const [comment, setComment] = useState("");
  
    const theme = useTheme();
    const match = useMediaQuery(theme.breakpoints.down("sm"));

  const admin = JSON.parse(sessionStorage.getItem('Admin'));

  
    const handleClose = () => {
      setOpen(false);
      setChecked([]);
      setComment("");
    };
  
    const handleChange = (event) => {
      const { checked, value } = event.target;
  
      if (checked) {
        setChecked((prevChecked) => [...prevChecked, value]);
      } else {
        setChecked((prevChecked) => [
          ...prevChecked,
          prevChecked.filter((item) => item !== value),
        ]);
      }
    };
  
    const ReportReview = async () => {
      try {
        setLoading(true);
        const comments =
          comment !== "" ? `${checked.join(",")  }, ${  comment}` : checked.join(",");
        const createData = {
          ratingID: id,
          comment: comments,
          reportedBy: admin.userID,
        };
  
        const { error } = await supabase.from("Report").insert(createData);
  
        if (error) {
          setLoading(false);
          console.error("Error create data:", error);
        } else {
          get(0,9,true);
          setLoading(false);
          handleClose();
          sucess(open);
        }
      } catch (error) {
        console.error("Error create data:", error);
        setLoading(false);
      }
    };
  
    return (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        {/* <DialogTitle>Are you sure You want to delete this review?</DialogTitle> */}
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            color="error"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon sx={{ color: "#d32f2f" }} />
          </IconButton>
  
          <Box m={3} mb={0}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.some(
                      (cat) => cat === "Inappropriate Language"
                    )}
                    onChange={handleChange}
                    value={"Inappropriate Language"}
                    color="error"
                    size={match ? "small" : "medium"}
                  />
                }
                label={
                  <Typography variant={match ? "caption" : "body2"}>Inappropriate Language</Typography>
                }
              />
            </FormGroup>
  
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.some((cat) => cat === "False Information")}
                    onChange={handleChange}
                    value={"False Information"}
                    color="error"
                    size={match ? "small" : "medium"}
                  />
                }
                label={<Typography variant={match ? "caption" : "body2"}>False Information</Typography>}
              />
            </FormGroup>
  
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.some(
                      (cat) => cat === "Spam or Self-Promotion"
                    )}
                    onChange={handleChange}
                    value={"Spam or Self-Promotion"}
                    color="error"
                    size={match ? "small" : "medium"}
                  />
                }
                label={
                  <Typography variant={match ? "caption" : "body2"}>Spam or Self-Promotion</Typography>
                }
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.some((cat) => cat === "Personal Attacks")}
                    onChange={handleChange}
                    value={"Personal Attacks"}
                    color="error"
                    size={match ? "small" : "medium"}
                  />
                }
                label={<Typography variant={match ? "caption" : "body2"}>Personal Attacks</Typography>}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.some((cat) => cat === "Irrelevant Content")}
                    onChange={handleChange}
                    value={"Irrelevant Content"}
                    color="error"
                    size={match ? "small" : "medium"}
                  />
                }
                label={
                  <Typography variant={match ? "caption" : "body2"}>Irrelevant Content</Typography>
                }
              />
            </FormGroup>
  
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              mt={2}
            >
              <Typography variant={match ? "caption" : "body2"}>Others</Typography>
              <TextField
                sx={{ ml: 2 }}
                id="report"
                value={comment}
                size="small"
                onChange={(e) => setComment(e.target.value)}
                placeholder="Write In It"
                variant="outlined"
                fullWidth
                multiline
                maxRows={2}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Button
            variant="contained"
            color="error"
            size="small"
            disabled={loading || (checked.length === 0 && comment === "")}
            onClick={ReportReview}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
