import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import CreateOfferPage from './pages/CreateOffer';
import CreateRestaurant from './pages/CreateRestaurant';
import DashboardAppPage from './pages/DashboardAppPage';
import FeaturePage from './pages/FeaturePage';
import LoginPage from './pages/LoginPage';
import MenuPage from './pages/MenuPage';
import OfferPage from './pages/OfferPage';
import Page404 from './pages/Page404';
import Pending from './pages/Pending';
import ProductsPage from './pages/ProductsPage';
import Gallery from './pages/Gallery';
import Settings from './pages/Settings';
import RestaurantSetting from './pages/RestaurantSetting';
import RegisterForm from './pages/RegisterForm';
import ReviewPage from './pages/ReviewPage';
import CreateBranch from './pages/CreateBranch';
import BranchSetting from './pages/BranchSetting';

// ----------------------------------------------------------------------

export default function Router() {
  const isUserLoggedIn = localStorage.getItem('User');
  const admin = JSON.parse(sessionStorage.getItem('Admin'));

  const renderDashboard = () => {
    if (isUserLoggedIn) {
      if (admin) {
        if (admin.restID.status === 'published' || admin.restID.status === 'test') {
          return <DashboardLayout />;
        }
        return <Navigate to="/pending" />;
      }
      return <Navigate to="/create-restaurant" />;
    }
    // If the user is not logged in, redirect to the login page
    return <Navigate to="/login" />;
  };

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: renderDashboard(),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        // { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'menu', element: <MenuPage /> },
        { path: 'offer', element: <OfferPage /> },
        { path: 'create-offer', element: <CreateOfferPage /> },
        { path: 'create-branch', element: <CreateBranch /> },
        { path: 'feature', element: <FeaturePage /> },
        { path: 'gallery', element: <Gallery /> },
        { path: 'review', element: <ReviewPage /> },
        {
          path: 'settings',
          element: <Settings />,
          children: [
            { element: <Navigate to="/dashboard/settings/restaurant" />, index: true },
            { path: 'restaurant', element: <RestaurantSetting /> },
            { path: 'branch', element: <BranchSetting /> },
          ],
        },
        // { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: isUserLoggedIn ? <Navigate to="/dashboard/app" /> : <LoginPage />,
    },
    { path: 'register', element: <RegisterForm /> },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        {
          path: '/create-restaurant',
          element: isUserLoggedIn ? (
            admin !== null ? (
              admin.restID.status === 'published' || admin.restID.status === 'test' ? (
                <Navigate to="/dashboard/app" />
              ) : (
                <Navigate to="/pending" />
              )
            ) : (
              <CreateRestaurant />
            )
          ) : (
            <Navigate to="/login" />
          ),
        },

        {
          path: '/pending',
          element: isUserLoggedIn ? (
            admin !== null ? (
              admin.restID.status === 'published' || admin.restID.status === 'test' ? (
                <Navigate to="/dashboard/app" />
              ) : (
                <Pending />
              )
            ) : (
              <CreateRestaurant />
            )
          ) : (
            <Navigate to="/login" />
          ),
        },

        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
