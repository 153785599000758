import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Pending() {
    return (
        <>
            <Helmet>
                <title> Pending | FoodieDa </title>
            </Helmet>

            <Container>
                <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
                    <Typography variant="h3" paragraph>
                        Restaurant Created
                    </Typography>

                    <Typography sx={{ color: 'text.secondary' }}>
                        Thank you for creating your restaurant! We have received your submission, and our team is currently reviewing it. Please wait for admin approval. We'll notify you as soon as your restaurant is approved and live on our platform. We appreciate your patience and look forward to showcasing your culinary delights to our hungry customers
                    </Typography>
                </StyledContent>
            </Container>
        </>
    );
}
