import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Card, Container, Grid, Stack, Typography, Rating, CircularProgress, Button } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReviewCard from '../components/review/reviewCard';
import { supabase } from '../supabase/supabase.config';

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  // width:130,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    backgroundColor: theme.palette.mode === 'light' ? '#faaf00' : '#308fe8',
  },
}));

export default function ReviewPage() {
  const [gallery, setGallery] = useState([]);
  const admin = JSON.parse(sessionStorage.getItem('Admin'));
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [pageNO, setPageNO] = useState([]);
  const [url, setUrl] = useState();
  const [mode, setMode] = useState('create');
  const [id, setId] = useState();
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [fiveStarPercentage, setFiveStartPercentage] = useState(0);
  const [fourStarPercentage, setFourStartPercentage] = useState(0);
  const [threeStarPercentage, setThreeStartPercentage] = useState(0);
  const [twoStarPercentage, setTwoStartPercentage] = useState(0);
  const [oneStarPercentage, setOneStartPercentage] = useState(0);
  const [totalRatings, setTotalRatings] = useState(0);
  const [restLoadMore, setRestLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [restaurantPage, setRestaurantPage] = useState(0);
  const [perRestaurantPage, setPerRestaurantPage] = useState(9);

  useEffect(() => {
    getReview(restaurantPage, perRestaurantPage, true);
  }, []);

  useEffect(() => {
    rating();
  }, [filteredReviews]);

  useEffect(() => {
    filterRating();
  }, [reviews]);

  const filterRating = () => {
    let data = reviews;
    // Remove objects where the report field length is greater than 5
    data = data.filter((obj) => obj.Report.length <= 5);

    setFilteredReviews(data);
  };

  const handleEndReachedRest = () => {
    if (!isLoading) {
      setIsLoading(true);
      const page = restaurantPage + 10;
      const perPage = perRestaurantPage + 10;
      setRestaurantPage((prevPage) => prevPage + 10);
      setPerRestaurantPage((prevPage) => prevPage + 10);
      getReview(page, perPage, false);
    }
  };

  const getReview = async (page, perPage, isQuery) => {
    const restID = admin.restID.id;

    try {
      const { data, error } = await supabase
        .from('Review')
        .select(
          `
        *,
        userID(*),
        Report(*)
        `
        )
        .eq('restID', restID)
        .range(page, perPage);

      if (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      } else {
        const loadMore = data.length === 10;
        setRestLoadMore(loadMore);
        if (isQuery) {
          setReviews(data);
        } else {
          const arr = data;
          const uniqueData = arr.filter((newItem) => !reviews.some((oldItem) => oldItem.id === newItem.id));
          setReviews((prevData) => [...prevData, ...(uniqueData || [])]);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const rating = () => {
    const totalReview = reviews.length;
    const totalRating = reviews.reduce((total, review) => total + review.rating, 0);

    // Check if totalReview is zero before calculating average rating
    const aveRating = totalReview !== 0 ? totalRating / totalReview : 0;

    const ratingCounts = reviews.reduce((counts, review) => {
      counts[review.rating] = (counts[review.rating] || 0) + 1;
      return counts;
    }, {});

    const fivePercentage = ((ratingCounts[5] || 0) / totalReview) * 100;
    const fourPercentage = ((ratingCounts[4] || 0) / totalReview) * 100;
    const threePercentage = ((ratingCounts[3] || 0) / totalReview) * 100;
    const twoPercentage = ((ratingCounts[2] || 0) / totalReview) * 100;
    const onePercentage = ((ratingCounts[1] || 0) / totalReview) * 100;

    setAverageRating(aveRating);
    setTotalRatings(totalReview);
    setFiveStartPercentage(fivePercentage);
    setFourStartPercentage(fourPercentage);
    setThreeStartPercentage(threePercentage);
    setTwoStartPercentage(twoPercentage);
    setOneStartPercentage(onePercentage);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Review | FoodieDa </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Review
        </Typography>

        {reviews && reviews.length !== 0 ? (
          <>
            <Card variant="outlined" sx={{ borderRadius: 3, width: '50%' }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <Grid container spacing={2} p={5} pb={5}>
                    <Grid item xs={4}>
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'space-around'}
                      >
                        <Typography variant="h4">{Number(averageRating.toFixed(1))}</Typography>
                        <Rating
                          name="simple-controlled"
                          value={averageRating}
                          precision={0.1}
                          // size={match ? "small" : "medium"}
                          readOnly
                          icon={<StarRoundedIcon fontSize="inherit" />}
                          emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
                        />
                        <Typography variant="caption" color="#faaf00">
                          ({totalRatings})
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={8}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={fiveStarPercentage}
                        // sx={{ height: 5, borderRadius: 2, mt: 1 }}
                        sx={{ mt: 1, width: 250 }}
                      />
                      <BorderLinearProgress
                        variant="determinate"
                        value={fourStarPercentage}
                        // sx={{ height: 5, borderRadius: 2, mt: 1 }}
                        sx={{ mt: 1, width: 250 }}
                      />
                      <BorderLinearProgress
                        variant="determinate"
                        value={threeStarPercentage}
                        // sx={{ height: 5, borderRadius: 2, mt: 1 }}
                        sx={{ mt: 1, width: 250 }}
                      />
                      <BorderLinearProgress
                        variant="determinate"
                        value={twoStarPercentage}
                        // sx={{ height: 5, borderRadius: 2, mt: 1 }}
                        sx={{ mt: 1, width: 250 }}
                      />
                      <BorderLinearProgress
                        variant="determinate"
                        value={oneStarPercentage}
                        // sx={{ height: 5, borderRadius: 2, mt: 1 }}
                        sx={{ mt: 1, width: 250 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

            {filteredReviews &&
              filteredReviews.length !== 0 &&
              filteredReviews.map((review, index) => (
                <Box sx={{ pt: 2, pb: 0 }} id="section-6" key={index}>
                  <ReviewCard data={review} get={getReview} />
                </Box>
              ))}

            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={2}>
              {isLoading ? (
                <CircularProgress size={20} color="error" />
              ) : (
                <>
                  {restLoadMore && reviews.length >= 10 && (
                    <Button
                      variant="text"
                      color="error"
                      endIcon={<KeyboardArrowDownIcon />}
                      onClick={handleEndReachedRest}
                    >
                      See All Reviews
                    </Button>
                  )}
                </>
              )}
            </Box>
          </>
        ) : (
          <Card variant="outlined" sx={{ borderRadius: 3, width: '50%' ,p:2,margin:"auto"}}>
            <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
              <Box component={'img'} src="/assets/noreivew.jpg" alt="Review" sx={{ height: 200, width: 200 }} />

              <Typography variant="body2">No reviews found</Typography>
            </Stack>
          </Card>
        )}
      </Container>
    </>
  );
}
