import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Avatar,
  Button,
  Divider,
  Container,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  Stack,
  Box,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Map from '../components/map/map';
import { supabase } from '../supabase/supabase.config';

export default function BranchSetting() {
  const navigate = useNavigate();

  const newBranch = () => {
    navigate('/dashboard/create-branch');
  };

  const [rest, setRest] = useState([]);
  const admin = JSON.parse(sessionStorage.getItem('Admin'));

  useEffect(() => {
    init();
  }, []);

  const update = async (restID) => {
    try {
      const { data, error } = await supabase
        .from('RestaurantAdmin')
        .update({ restID })
        .eq('id', admin.id)
        .select(
          `
          *,
          restID(*)
          `
        );

      if (error) {
        console.error('Error fetching data:', error);
      } else {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const init = () => {
    const encode = sessionStorage.getItem('Branch');

    if (encode) {
      // const encodedBytes = new Uint8Array(encode.split('').map((char) => char.charCodeAt(0)));
      const encodedBytesArray = encode.split(',').map(Number);
      const retrievedEncodedBytes = new Uint8Array(encodedBytesArray);
      const decoder = new TextDecoder();
      const decoded = decoder.decode(retrievedEncodedBytes);
      const data = JSON.parse(decoded);
      setRest(data);
    }
  };

  return (
    <>
      <Container>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
          <Button variant="contained" color="error" size="small" endIcon={<AddIcon />} onClick={newBranch}>
            New Branch
          </Button>
        </Stack>
        {(rest.length === 0 || rest.length) === 1 ? (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={10}>
            <Box component={'img'} src="/assets/branch.png" />
            <Typography variant="body2" mt={1}>
              You can add your branches if you have
            </Typography>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={1}>
            <List sx={{ width: '100%', maxWidth: 500 }}>
              {rest
                .filter((option) => option.id !== admin.restID.id)
                .map((item, index) => (
                  <>
                    {index !== 0 && <Divider variant="fullWidth" component="li" />}
                    <ListItem
                      alignItems="flex-start"
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          // Targeting the Typography and Icon to decrease their opacity on hover
                          '& .MuiTypography-root, & .icon': {
                            opacity: 0.7,
                          },
                        },
                      }}
                      onClick={() => update(item.id)}
                    >
                      <ListItemText
                        primary={item.name}
                        secondary={
                          <>
                            <Typography
                              sx={{ display: 'inline', alignItems: 'center', gap: 1 }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                                <PlaceOutlinedIcon />
                              </ListItemIcon>
                              {item.address}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  </>
                ))}
            </List>
          </Box>
        )}
      </Container>
    </>
  );
}
