// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  {
    title: 'menu',
    path: '/dashboard/menu',
    icon: icon('ic_menu'),
  },
  {
    title: 'offers',
    path: '/dashboard/offer',
    icon: icon('ic_offer'),
  },
  {
    title: 'features',
    path: '/dashboard/feature',
    icon: icon('ic_feature'),
  },
  {
    title: 'gallery',
    path: '/dashboard/gallery',
    icon: icon('ic_gallery'),
  },
  {
    title: 'review',
    path: '/dashboard/review',
    icon: icon('ic_review'),
  },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
