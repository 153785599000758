import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
// @mui
import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  CircularProgress,
  TextField,
  Typography,
  Box,
  Stack,
  Avatar,
} from '@mui/material';
import { Parser } from 'binary-parser';
import { supabase } from '../supabase/supabase.config';
import Map from '../components/map/map';
import Maps from '../components/map/maps';

const Buffer = require('buffer').Buffer;

export default function CreateBranch() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [image, setImage] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [markerPosition, setMarkerPosition] = useState({
    lat: 9.661451499519952,
    lng: 80.02569546699601,
  });
  const [submitMessage, setSubmitMessage] = useState('sample message');
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [open, setOpen] = useState(false);
  const [rest, setRest] = useState([]);
  const [restData, setRestData] = useState({});

  const admin = JSON.parse(sessionStorage.getItem('Admin'));

  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, []);

  const getBranch = async () => {
    try {
      const branches = await supabase
        .from('Restaurant')
        .select(`*`)
        .or(`id.eq.${admin.restID.id},mainBranch.eq.${admin.restID.id}`);

      if (branches.error) {
        console.error('Error fetching data:', branches.error);
        sessionStorage.removeItem('Branches');
      } else {
        const encoder = new TextEncoder();
        const dataString = JSON.stringify(branches.data);
        const encode = encoder.encode(dataString);

        sessionStorage.setItem('Branch', encode.toString());
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Convert hexadecimal string to ArrayBuffer
  function hexStringToByteArray(hexString) {
    return Buffer.from(hexString, 'hex');
  }

  const init = () => {
    const encode = sessionStorage.getItem('Branch');

    if (encode) {
      // const encodedBytes = new Uint8Array(encode.split('').map((char) => char.charCodeAt(0)));
      const encodedBytesArray = encode.split(',').map(Number);
      const retrievedEncodedBytes = new Uint8Array(encodedBytesArray);
      const decoder = new TextDecoder();
      const decoded = decoder.decode(retrievedEncodedBytes);
      console.log(JSON.parse(decoded));
      const data = JSON.parse(decoded);
      console.log(decoded);
      setRest(data);
      const result = data.filter((obj) => obj.isBranch === false);

      setRestData(result[0]);

      console.log(result[0].shortDescription);
      formik.setValues({
        description: result[0].shortDescription,
      });
      setAvatar(result[0].logo);
      setImage(result[0].bannerImg);

      // // Sample PostGIS point value
      // const postgisPointValue = result[0].loc_point;

      // // Define the parser structure
      // const pointParser = new Parser().endianess('little').uint8('byteOrder').doublele('x').doublele('y');

      // // Parse the PostGIS point value
      // const parsedPoint = pointParser.parse(hexStringToByteArray(postgisPointValue));

      // // Extract the coordinates
      // const { x } = parsedPoint;
      // const { y } = parsedPoint;

      // console.log({
      //   lat: y,
      //   lng: x,
      // });

      // setMarkerPosition({
      //   lat: x,
      //   lng: y,
      // });
    }
  };
  const openSnackbar = (msg) => {
    setSubmitMessage(msg);
    setIsSubmiting(false);
    setOpen(true);
  };

  const createBranch = () => {
    if (selectedAvatar === null && avatar === null) {
      openSnackbar('Please Select a logo');
    } else if (selectedImage === null && image === null) {
      openSnackbar('Please Select a banner image');
    } else if (markerPosition === null) {
      openSnackbar('Please Select a location');
    } else {
      createEvent();
    }
  };

  const generateUniquePath = (prefix) => {
    const uniqueIdentifier = new Date().getTime(); // You can use a different method to generate a unique identifier
    return `${prefix}/${uniqueIdentifier}`;
  };
  const uploadImage = async (folder, name, image) => {
    const uniquePath = generateUniquePath(name);

    const result = await supabase.storage.from(folder).upload(uniquePath, image, {
      // cacheControl: '1',
      upsert: false,
    });
    if (result.error) {
      console.log('network issue', result.error);
      openSnackbar('Some Error occurred. Please try again later.');
      return null;
    }
    return result;
  };

  const createEvent = async () => {
    setIsLoading(true);
    const { name, phone, address, description } = formik.values;

    const insert = {
      name,
      phone,
      address,
      shortDescription: description,
      isBranch: true,
      mainBranch: restData.id,
      loc_point: `POINT(${markerPosition.lng} ${markerPosition.lat})`,
      status: 'test',
    };

    if (selectedAvatar !== null) {
      const logo = await uploadImage('logos', `${name}-`, selectedAvatar);

      if (logo) {
        const logoImg = await supabase.storage.from('logos').getPublicUrl(logo.data.path);

        insert.logo = logoImg.data.publicUrl;
      }
    } else {
      insert.logo = avatar;
    }

    if (selectedImage !== null) {
      const banner = await uploadImage('images', `BannerImage`, selectedImage);

      if (banner) {
        const bannerImg = await supabase.storage.from('images').getPublicUrl(banner.data.path);

        insert.bannerImg = bannerImg.data.publicUrl;
      }
    } else {
      insert.bannerImg = image;
    }

    try {
      const { data, error } = await supabase.from('Restaurant').insert([insert]);

      if (error) {
        console.error('Error inserting data:', error);
        openSnackbar('Some Error occurred. Please try again later.');
        setIsLoading(false);
      } else {
        formik.resetForm();
        await getBranch();
        setIsLoading(false);
        navigate('/dashboard/settings/branch');
      }
    } catch (error) {
      console.error('Error inserting data:', error);
    }
  };

  const handleChildData = (data) => {
    // Update the parent component state with data from the child
    setMarkerPosition(data);
  };

  const handleAvatarSelect = (event) => {
    const file = event.target.files[0];
    setSelectedAvatar(file);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleClose}>
        OK
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        x
      </IconButton>
    </>
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      address: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .notOneOf(
          rest.map((restaurant) => restaurant.name),
          'Restaurant name already exists'
        )
        .required('Restaurant name is required'),
      address: Yup.string()
        .notOneOf(
          rest.map((restaurant) => restaurant.address),
          'Restaurant address already exists'
        )
        .required('Restaurant address is required'),
      phone: Yup.string()
        .matches(/^(?:0)?(?:7(?:0|1|2|5|6|7|8)\d{7})$/, 'Invalid phone number 07xxxxx')
        .required('Phone number is required'),
      description: Yup.string(),
    }),
    onSubmit: (values) => {
      // handleClickOpen();
      // register();
      console.log(values);

      createBranch();
    },
  });

  return (
    <>
      <Helmet>
        <title> Dashboard: Create-Branch | FoodieDa </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          New Branch
        </Typography>

        <Box sx={{ p: 4, mt: 2, width: '60%', margin: 'auto' }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="avatar-input"
                      onChange={handleAvatarSelect}
                    />
                    <Avatar
                      alt="Selected Image"
                      src={
                        selectedAvatar
                          ? URL.createObjectURL(selectedAvatar)
                          : avatar !== null
                          ? avatar
                          : '/assets/addimage.jpg'
                      } // Replace with your default avatar image
                      sx={{
                        width: 100,
                        height: 100,
                      }}
                    />

                    <IconButton
                      aria-label="delete"
                      style={{ position: 'absolute', bottom: 5, right: 5 }}
                      onClick={() => {
                        document.getElementById('avatar-input').click();
                      }}
                    >
                      <Icon
                        icon="pepicons-pop:photo-camera-circle-filled"
                        style={{
                          // color: '#bd1522',
                          color: 'white',
                          width: 24,
                          height: 24,
                        }}
                      />
                    </IconButton>
                  </div>
                  <Typography variant="body2">Logo</Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="image-input"
                      onChange={handleImageSelect}
                    />
                    <Box
                      component={'img'}
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : image !== null
                          ? image
                          : '/assets/addimage.jpg'
                      }
                      alt="upload"
                      sx={{ width: 200, height: 100, objectFit: 'cover' }}
                    />

                    <IconButton
                      aria-label="delete"
                      style={{ position: 'absolute', bottom: 0, right: 0 }}
                      onClick={() => {
                        document.getElementById('image-input').click();
                      }}
                    >
                      <Icon
                        icon="pepicons-pop:photo-camera-circle-filled"
                        style={{
                          // color: '#bd1522',
                          color: 'white',
                          width: 24,
                          height: 24,
                        }}
                      />
                    </IconButton>
                  </div>
                  <Typography variant="body2">Banner</Typography>
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  sx={{ my: 1 }}
                  id="name"
                  name="name"
                  required
                  fullWidth
                  label="New Branch Name"
                  size="small"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ my: 1 }}
                  id="phone"
                  name="phone"
                  required
                  fullWidth
                  label="Phone Number"
                  placeholder="07xxxxxx"
                  size="small"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  sx={{ my: 1 }}
                  id="address"
                  name="address"
                  required
                  fullWidth
                  label="Address"
                  size="small"
                  multiline
                  maxRows={4}
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ my: 1 }}
                  id="description"
                  name="description"
                  fullWidth
                  required
                  label="Description"
                  size="small"
                  multiline
                  maxRows={4}
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>
              <Grid item xs={12}>
                {markerPosition && (
                  // <Map center={markerPosition} height="300px" zoom={8} onDataFromChild={handleChildData} />
                  <Maps
                    containerStyle={{ width: '100%', height: '300px', borderRadius: 2 }}
                    center={markerPosition}
                    zoom={14}
                    onDataFromChild={handleChildData}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {/* {isSubmiting ? (
                <>
                  <Button variant="contained" fullWidth>
                    <CircularProgress color="inherit" />
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="contained" fullWidth>
                    Submit
                  </Button>
                </>
              )} */}
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                  <LoadingButton
                    variant="contained"
                    color="error"
                    size="small"
                    type="submit"
                    loading={loading}
                    sx={{ backgroundColor: '#bd1522', width: 150, borderRadius: 2 }}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              </Grid>
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={submitMessage}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              />
            </Grid>
          </form>
        </Box>
      </Container>
    </>
  );
}
