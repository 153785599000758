import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Avatar, Box, Drawer, IconButton, Link, Stack, Typography, Popover, MenuItem } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import NavSection from '../../../components/nav-section';
import Scrollbar from '../../../components/scrollbar';
//
import navConfig from './config';
import { supabase } from '../../../supabase/supabase.config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(null);
  const [rest, setRest] = useState([]);
  const isDesktop = useResponsive('up', 'lg');

  const admin = JSON.parse(sessionStorage.getItem('Admin'));

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    const encode = sessionStorage.getItem('Branch');

    if (encode) {
      // const encodedBytes = new Uint8Array(encode.split('').map((char) => char.charCodeAt(0)));
      const encodedBytesArray = encode.split(',').map(Number);
      const retrievedEncodedBytes = new Uint8Array(encodedBytesArray);
      const decoder = new TextDecoder();
      const decoded = decoder.decode(retrievedEncodedBytes);
      const data = JSON.parse(decoded);
      setRest(data);
    }
  };

  const update = async (restID) => {
    try {
      const { data, error } = await supabase
        .from('RestaurantAdmin')
        .update({ restID })
        .eq('id', admin.id)
        .select(
          `
          *,
          restID(*)
          `
        );

      if (error) {
        console.error('Error fetching data:', error);
      } else {
        window.location.reload()
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCilck = (restID) => {
    update(restID);
    handleClose();
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps

    let user = localStorage.getItem('User');
    if (user) {
      user = JSON.parse(user);
      setUser(user);
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            {/* <Avatar src={user?.user_metadata?.avatar_url} alt="photoURL" /> */}

            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {/* {user?.user_metadata?.full_name} */}
              {admin.restID.name}
            </Typography>

            <Box sx={{ ml: 2 }}>
              <IconButton onClick={handleOpen}>
                <SyncAltIcon color="disabled" />
              </IconButton>

              <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: {
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': {
                      typography: 'body2',
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                <Stack sx={{ p: 1 }}>
                  {rest
                    .filter((option) => option.id !== admin.restID.id) // replace yourId with the ID you want to exclude
                    .map((option) => (
                      <MenuItem key={option.id} onClick={() => handleCilck(option.id)}>
                        {option.name}
                      </MenuItem>
                    ))}
                </Stack>
              </Popover>

              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography> */}
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          {/* <Box
            component="img"
            src="/assets/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button> */}
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
