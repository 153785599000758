import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Modal,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Divider,
  TextField,
  Typography,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Stack,
  Checkbox,
  Link,
  Snackbar,
  InputAdornment,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { supabase } from '../supabase/supabase.config';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 800,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const [open, setOpen] = useState(false);
  const [snack, setSnack] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('sample message');
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloses = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const register = async () => {
    setIsLoading(true);
    const { name, owner, address, phone, email, district } = formik.values;
    const insert = {
      name,
      owner,
      address,
      phone,
      email,
      district,
    };
    try {
      const { data, error } = await supabase.from('RestaurantRegister').insert([insert]);

      if (error) {
        console.error('Error inserting data:', error);
        openSnackbar('Some Error occurred. Please try again later.');
        setIsLoading(false);
      } else {
        formik.resetForm();
        handleOpen();
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error inserting data:', error);
    }
  };

  const openSnackbar = (msg) => {
    setSubmitMessage(msg);
    setIsLoading(false);
    setOpen(true);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      owner: '',
      address: '',
      phone: '',
      email: '',
      district: '',
      agreed: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Restaurant name is required'),
      owner: Yup.string(),
      address: Yup.string(),
      phone: Yup.string()
        .matches(/^(?:0)?(?:7(?:0|1|2|5|6|7|8)\d{7})$/, 'Invalid phone number +94xxxxx')
        .required('Phone number is required'),
      email: Yup.string().email('Invalid email address'),
      district: Yup.string(),
      agreed: Yup.boolean().oneOf([true], 'You must agree to the terms').required('You must agree to the terms'),
    }),
    onSubmit: (values) => {
      // handleClickOpen();

      register();
      // console.log(values);
    },
  });

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleCloses}>
        OK
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloses}>
        x
      </IconButton>
    </>
  );

  const sriLankanDistricts = [
    'Jaffna',
    'Kilinochchi',
    'Mannar',
    'Vavuniya',
    'Mullaitivu',
    'Batticaloa',
    'Ampara',
    'Trincomalee',
    'Kandy',
    'Matale',
    'Nuwara Eliya',
    'Kurunegala',
    'Puttalam',
    'Anuradhapura',
    'Polonnaruwa',
    'Colombo',
    'Gampaha',
    'Kalutara',
    'Galle',
    'Matara',
    'Hambantota',
    'Badulla',
    'Moneragala',
    'Ratnapura',
    'Kegalle',
  ];

  return (
    <>
      <Helmet>
        <title> Register | FoodieDa </title>
      </Helmet>

      <Container>
        <StyledContent>
          <Card sx={{ padding: 10, '@media (max-width:600px)': { padding: 5 }, paddingTop: 5, paddingBottom: 5 }}>
            <form onSubmit={formik.handleSubmit}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box component="img" src="/assets/registerLogo.png" sx={{ width: 200, height: 100 }} />
              </Box>
              <Divider sx={{ mb: 3, mt: 3 }} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Restaurant Name</Typography>

                  <TextField
                    label="Name"
                    required
                    id="name"
                    name="name"
                    size="small"
                    fullWidth
                    sx={{ my: 1 }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Restaurant Owner</Typography>
                  <TextField
                    label="Owner"
                    id="owner"
                    name="owner"
                    size="small"
                    fullWidth
                    sx={{ my: 1 }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.owner}
                    error={formik.touched.owner && Boolean(formik.errors.owner)}
                    helperText={formik.touched.owner && formik.errors.owner}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">Restaurant Address</Typography>
                  <TextField
                    label="Enter Your Address"
                    id="address"
                    name="address"
                    size="small"
                    fullWidth
                    sx={{ my: 1 }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">Phone Number</Typography>

                  <TextField
                    label="Enter Your Number"
                    required
                    id="phone"
                    name="phone"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+94</InputAdornment>,
                    }}
                    fullWidth
                    sx={{ my: 1 }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Email</Typography>
                  <TextField
                    label="Enter Your Email"
                    id="email"
                    name="email"
                    size="small"
                    fullWidth
                    sx={{ my: 1 }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">District</Typography>
                  <FormControl fullWidth size="small" sx={{ my: 1 }}>
                    <InputLabel id="district">Select Your District</InputLabel>
                    <Select
                      labelId="district"
                      id="district"
                      name="district"
                      value={formik.values.district}
                      label="District"
                      onChange={formik.handleChange}
                      error={formik.touched.district && Boolean(formik.errors.district)}
                      helperText={formik.touched.district && formik.errors.district}
                    >
                      {sriLankanDistricts.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Stack direction="row" alignItems="center" justifyContent="center">
                <Checkbox
                  id="agreed"
                  name="agreed"
                  value={formik.values.agreed}
                  onChange={formik.handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography variant="body2" ml={2}>
                  I agree to the <Link href="https://www.foodieda.com/privacy">Terms & Conditions</Link> and{' '}
                  <Link href="https://www.foodieda.com/privacy">Privacy Policy</Link>
                </Typography>
              </Stack>
              <Box display="flex" alignItems="center" justifyContent="center">
                {formik.touched.agreed && !formik.values.agreed && (
                  <Typography variant="caption" color="error" textAlign="center">
                    You must agree to the Terms & Conditions and Privacy Policy.
                  </Typography>
                )}
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  size="large"
                  type="submit"
                  sx={{
                    width: 200,
                    backgroundColor: '#DD1022',
                    '&:hover': {
                      backgroundColor: '#DD1022',
                    },
                  }}
                >
                  Submit
                </LoadingButton>
              </Box>
            </form>
          </Card>
        </StyledContent>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box component="img" src="/assets/done.png" sx={{ width: 50, height: 50 }} />
          </Box>
          <Typography id="modal-modal-description" textAlign="center" sx={{ mt: 2 }}>
            Good Job! Thanks For Submiting The Form!
          </Typography>
        </Box>
      </Modal>

      <Snackbar
        open={snack}
        autoHideDuration={6000}
        onClose={handleCloses}
        message={submitMessage}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </>
  );
}
