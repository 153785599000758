import { useEffect, useState } from 'react';
import { BrowserRouter, json } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { supabase } from './supabase/supabase.config';

// ----------------------------------------------------------------------

export default function App() {
  const user = JSON.parse(localStorage.getItem('User'));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('RestaurantAdmin')
        .select(
          `
      *,
      restID(*)
      `
        )
        .eq('userID', user.id)
        .single()
        .limit(1);

      if (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
        sessionStorage.removeItem('Admin');
      } else {
        sessionStorage.setItem('Admin', JSON.stringify(data));

        let id;
        if (data.restID.isBranch) {
          id = data.restID.mainBranch;
        } else {
          id = data.restID.id;
        }

        const branches = await supabase
          .from('Restaurant')
          .select(`*`)
          .or(`id.eq.${id},mainBranch.eq.${id}`);

        if (branches.error) {
          setLoading(false);
          console.error('Error fetching data:', error);
          sessionStorage.removeItem('Branches');
        } else {
          const encoder = new TextEncoder();
          const dataString = JSON.stringify(branches.data);
          const encode = encoder.encode(dataString);

          sessionStorage.setItem('Branch', encode.toString());
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          {!loading && <Router />}
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
