import LoadingButton from '@mui/lab/LoadingButton';
import { useState, useEffect } from 'react';

// @mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Container,
  Snackbar,
  IconButton,
  TextField,
} from '@mui/material';

import { supabase } from '../../supabase/supabase.config';

export default function MenuDialog({ closeModel, page, mode, id }) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [pageNO, setPageNO] = useState(null);
  const [no, setNo] = useState(null);
  const [submitMessage, setSubmitMessage] = useState('sample message');

  useEffect(() => {
    if (mode === 'update') {
      fetch();
    }
  }, []);

  const openSnackbar = (msg) => {
    setSubmitMessage(msg);
    setIsLoading(false);
    setOpen(true);
  };

  const handleCloseModel = () => {
    closeModel(false);
  };
  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleNumericInputChange = (event) => {
    const inputValue = event.target.value;
    // Use a regular expression to remove non-numeric characters
    const numericInput = inputValue.replace(/[^0-9]/g, '');

    setPageNO(numericInput);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const generateUniquePath = (prefix) => {
    const uniqueIdentifier = new Date().getTime(); // You can use a different method to generate a unique identifier
    return `${prefix}/${uniqueIdentifier}`;
  };

  const uploadImage = async (folder, name, image) => {
    const uniquePath = generateUniquePath(name);

    const result = await supabase.storage.from(folder).upload(uniquePath, image, {
      // cacheControl: '1',
      upsert: false,
    });
    if (result.error) {
      console.log('network issue', result.error);
      openSnackbar('Some Error occurred. Please try again later.');
      setIsLoading(false);
      return null;
    }
    return result;
  };

  const createMenu = async () => {
    const admin = JSON.parse(sessionStorage.getItem('Admin'));

    const menu = await uploadImage('images', `MenuImages/${admin.restID.name}`, selectedImage);
    console.log(menu);

    if (menu !== null) {
      console.log(menu.data);
      const menuUrl = await supabase.storage.from('images').getPublicUrl(menu.data.path);

      const data = {
        restID: admin.restID.id,
        page_no: pageNO,
        url: menuUrl.data.publicUrl,
      };

      const create = await supabase.from('MenuImage').insert([data]).select();

      if (create.error) {
        console.log('network issue', create.error);
        openSnackbar('Some Error occurred. Please try again later.');
        setIsLoading(false);
      } else {
        openSnackbar('Menu added');
        handleCloseModel();
        setIsLoading(false);
      }
    } else {
      openSnackbar('Some Error occurred. Please try again later.');
      setIsLoading(false);
    }
  };

  const updateMenu = async () => {
    const admin = JSON.parse(sessionStorage.getItem('Admin'));
    if (selectedImage !== null) {
      const urlParts = image.split('/');

      // Get the last part (file name)
      const fileName = urlParts[urlParts.length - 1];

      const { data, error } = await supabase.storage
        .from('images')
        .remove([`MenuImages/${admin.restID.name}/${fileName}`]);

      if (error) {
        console.log('network issue', error);
        openSnackbar('Some Error occurred. Please try again later.');
        setIsLoading(false);
      } else {
        const uploadImage = async (folder, name, image) => {
          const uniquePath = generateUniquePath(name);

          const result = await supabase.storage.from(folder).upload(uniquePath, image, {
            // cacheControl: '1',
            upsert: false,
          });
          if (result.error) {
            console.log('network issue', result.error);
            openSnackbar('Some Error occurred. Please try again later.');
            setIsLoading(false);
            return null;
          }
          return result;
        };

        const menu = await uploadImage('images', `MenuImages/${admin.restID.name}`, selectedImage);
        console.log(menu);

        if (menu !== null) {
          console.log(menu.data);

          const menuUrl = await supabase.storage.from('images').getPublicUrl(menu.data.path);

          const data = {
            page_no: pageNO,
            url: menuUrl.data.publicUrl,
          };

          const create = await supabase.from('MenuImage').update(data).eq('id', id);

          if (create.error) {
            console.log('network issue', create.error);
            openSnackbar('Some Error occurred. Please try again later.');
            setIsLoading(false);
          } else {
            openSnackbar('Menu added');
            handleCloseModel();
            setIsLoading(false);
          }
        } else {
          openSnackbar('Some Error occurred. Please try again later.');
          setIsLoading(false);
        }
      }
    } else {
      const data = {
        page_no: pageNO,
      };

      const menu = await supabase.from('MenuImage').update(data).eq('id', id);

      if (menu.error) {
        console.log('network issue', menu.error);
        openSnackbar('Some Error occurred. Please try again later.');
        setIsLoading(false);
      } else {
        openSnackbar('Menu added');
        handleCloseModel();
        setIsLoading(false);
      }
    }
  };

  const fetch = async () => {
    try {
      const { data, error } = await supabase.from('MenuImage').select(`*`).eq('id', id).single().limit(1);

      if (error) {
        console.error('Error fetching data:', error);
      } else {
        setPageNO(data.page_no);
        setNo(data.page_no);
        setImage(data.url);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleAdd = () => {
    setIsLoading(true);
    if (selectedImage === null) {
      openSnackbar('Please Select a banner image');
    } else if (pageNO === null) {
      openSnackbar('Please Enter a page number');
    } else if (page.includes(parseInt(pageNO, 10))) {
      openSnackbar('Page Number already exists');
    } else {
      createMenu();
    }
  };

  const handleUpdate = () => {
    setIsLoading(true);
    if (selectedImage === null && image !== null) {
      openSnackbar('Please Select a banner image');
    } else if (pageNO === null) {
      openSnackbar('Please Enter a page number');
    } else if (page.includes(parseInt(pageNO, 10)) && no !== pageNO) {
      openSnackbar('Page Number already exists');
    } else {
      updateMenu();
    }
  };
  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleCloseModel}>
        OK
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseModel}>
        x
      </IconButton>
    </>
  );

  return (
    <>
      <Dialog open={closeModel} onClose={handleCloseModel} aria-labelledby="category-form" fullWidth>
        <DialogTitle id="category-form">Menu</DialogTitle>

        <DialogContent>
          <DialogContentText>Select the image and page number below</DialogContentText>

          <Stack direction="row" spacing={2} mt={2} alignItems="center" justifyContent="space-evenly">
            <div>
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                id="image-input"
                onChange={handleImageSelect}
              />
              <Container
                onClick={() => {
                  document.getElementById('image-input').click();
                }}
                sx={{
                  width: 200,
                  height: 200,
                  border: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'lightgray', // Change the hover effect as desired
                  },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden', // Hide any overflowing content
                }}
              >
                <img
                  src={
                    selectedImage ? URL.createObjectURL(selectedImage) : image !== null ? image : '/assets/upload.png'
                  }
                  alt="upload"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Container>
            </div>
            <TextField
              id="pageNo"
              value={pageNO}
              label="Page Number"
              variant="outlined"
              onChange={handleNumericInputChange}
              type="number"
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModel} color="secondary">
            Cancel
          </Button>
          {isLoading ? (
            <LoadingButton loading variant="outlined">
              Submit
            </LoadingButton>
          ) : (
            <Button onClick={() => (mode === 'create' ? handleAdd() : handleUpdate())} color="primary">
              {mode === 'create' ? 'Add' : 'Update'}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={submitMessage}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </>
  );
}
